import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentArea from "../components/contentArea"
import Heading from "../components/heading"

const NotFoundPage = () => (
    <Layout pageName="not-found">
        <ContentArea>
            <Heading {...{ level: 1, content: "404: Not Found" }} />
            <p>Sorry, the requested page could not be found.</p>
        </ContentArea>
    </Layout>
)

export const Head = () => <Seo title="404: Not found" />

export default NotFoundPage
